import { takeEvery } from 'redux-saga/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { actions } from '../../actions/actionsWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eBaiVietViewActionTypeIds, IBaiVietViewLoadStart } from './../../action-types/bai-viet/IBaiVietViewActionTypes';

const appBaiVietViewApi = apiWrapper.appBaiVietView;

export function* baiVietViewSaga(): any {
    yield takeLatest(eBaiVietViewActionTypeIds.LOAD_START, loadWorker)
}

function* loadWorker(action: IBaiVietViewLoadStart): any {
    const res: IBaseResponeModel = yield call([appBaiVietViewApi, appBaiVietViewApi.SelectAll], action.payload)
    if (res.is_success && res.data) {
        yield put(actions.baiVietView.loadSuccess(res.data))
    } else {
        yield put(actions.baiVietView.loadErr(res.message || "Không tải được danh sách"))
    }
}

