import { all } from "redux-saga/effects"
import accountSaga from "./accountSaga"
import { processAccountSaga } from "./processAccountSaga"
import { appAccountSaga } from "./app-phuhuynh/appAccountSaga"
import { appMenuNhomSaga } from "./app-phuhuynh/appMenuNhomSaga"
import { appMenuSaga } from "./app-phuhuynh/appMenuSaga"
import { appMenuTruongSaga } from "./app-phuhuynh/appMenuTruongSaga"
import { appXinNghiSaga } from "./app-phuhuynh/appXinNghiSaga"
import { authSaga } from "./authSaga"
import { baiTapKetQuaLopSaga } from "./bai-tap/baiTapKetQuaLopSaga"
import { baiTapKetQuaSaga } from "./bai-tap/baiTapKetQuaSaga"
import { baiTapSaga } from "./bai-tap/baiTapSaga"
import { baiTapTypeSaga } from "./bai-tap/baiTapTypeSaga"
import { baiVietDetailSaga } from "./bai-viet/baiVietDetailSaga"
import { baiVietSaga } from "./bai-viet/baiVietSaga"
import { baiVietTrangThaiSaga } from "./bai-viet/baiVietTrangThaiSaga"
import { baiVietViewSaga } from "./bai-viet/baiVietViewSaga"
import { baiVietReactSaga } from "./bai-viet/baiVietReactSaga"
import { categorySourceSaga } from "./categorySourceSagas"
import { dgdkNlpcSaga } from "./danh-gia/dinh-ky/dgdkNlpcSaga"
import { dgtxKetQuaMonHocSaga } from "./danh-gia/thuong-xuyen/dgtxKetQuaMonHocSaga"
import { dgtxKetQuaSaga } from "./danh-gia/thuong-xuyen/dgtxKetQuaSaga"
import { hocKySaga } from "./danh-muc/hocKySaga"
import { lopSaga } from "./danh-muc/lopSaga"
import { plNamHocSaga } from "./danh-muc/plNamHocSaga"
import { thuSaga } from "./danh-muc/thuSaga"
import { tietSaga } from "./danh-muc/tietSaga"
import { tuanSaga } from "./danh-muc/tuanSaga"
import { dictionarySaga } from "./dictionarySagas"
import { diemDanhSaga } from "./diem-danh/diemDanhSaga"
import { diemDanhStatusSaga } from "./diem-danh/diemDanhStatusSaga"
import { giaoVienNhanXetSaga } from "./giaoVienNhanXetSaga"
import { giaoVienSaga } from "./giaoVienSaga"
import { hocSinhSelectionSaga } from "./hoc-sinh/hocSinhSelectionSaga"
import { localizedResourceSaga } from "./localized-resource/localizedResourcsSaga"
import { lopBoMonHocSinhSaga } from "./lop-bo-mon/lopBoMonHocSinhSaga"
import { lopBoMonListSaga } from "./lop-bo-mon/lopBoMonListSaga"
import { lopBoMonSoDiemSaga } from "./lop-bo-mon/lopBoMonSoDiemSaga"
import { lopHanhChinhSoDiemSaga } from "./lop-hanh-chinh/lopHanhChinhSoDiemSaga"
import { mappingSaga } from "./mapping/mappingSaga"
import { monHocSaga } from "./monHocSaga"
import { nhanXetHangNgaySaga } from "./nhan-xet/nhanXetHangNgaySaga"
import { nhapDiemSaga } from "./nhap-diem/nhapDiemSaga"
import { notifySaga } from "./notify/notifySaga"
import { loaiPhongSaga } from "./phong-hoc/loaiPhongSaga"
import { phongHocSaga } from "./phong-hoc/phongHocSaga"
import { soNhanXetItemSaga } from "./so-diem-tieu-hoc/soNhanXetItemSaga"
import { soNhanXetSaga } from "./so-diem-tieu-hoc/soNhanXetSaga"

import { baoCaoTienDoSaga } from "./bao-cao-tien-do/baoCaoTienDoSaga"
import { dgdkHocTapSaga } from "./danh-gia/dinh-ky/dgdkHocTapSaga"
import { thongTuSaga } from "./danh-muc/thongTuSaga"
import { diemVanMinhKetQuaSaga } from "./diemvanminh-ketqua/diemVanMinhKetQuaSaga"
import { diemVanMinhTieuChiSaga } from "./diemvanminh-tieuchi/diemVanMinhTIeuChiSaga"
import { lopChuNhiemSaga } from "./lop-chu-nhiem/lopChuNhiemSaga"
import lopMonHocSaga from "./lop-mon-hoc/lopMonHocSaga"
import { thaiDoHocTapSaga } from "./nhan-xet/thaiDoHocTapSaga"
import { thaiDoHocTapTieuChiSaga } from "./nhan-xet/thaiDoHocTapTieuChiSaga"
import { giaiDoanSaga } from "./so-diem/giaiDoanSaga"
import { monHocSoDiemSaga } from "./so-diem/monHocSoDiemSaga"
import { nhomThanhPhanDiemSaga } from "./so-diem/nhomThanhPhanDiemSaga"
import { soDiemConfigSaga } from "./so-diem/soDiemCofigSaga"
import { soDiemListSaga } from "./so-diem/soDiemListSaga"
import { soDiemMappingSaga } from "./so-diem/soDiemMappingSaga"
import { thangDiemSaga } from "./so-diem/thangDiemSaga"
import { thanhPhanDiemSaga } from "./so-diem/thanhPhanDiemSaga"
import { thoiKhoaBieuChiTietSaga } from "./thoi-khoa-bieu/thoiKhoaBieuChiTietSaga"
import { thoiKhoaBieuSaga } from "./thoi-khoa-bieu/thoiKhoaBieuSaga"
import { toKhoiSaga } from "./toKhoiSaga"
import { tongHopDinhKySaga } from "./tong-hop/tongHopDinhKySaga"
import { uploadSaga } from "./upload-file/uploadSaga"
import { xepHangHocSinhSaga } from "./xep-hang-hoc-sinh/xepHangHocSinhSaga"
import { baiVietCommentSaga } from "./bai-viet-comment/baiVietCommentSaga"
import { appYeuCauHoTroSaga } from "./app-phuhuynh/appYeuCauHoTroSaga"
import { dotDangKySaga } from "./dangky-monhoc/dotDangKySaga"
import { dotDangKyNhomMonHocSaga } from "./dangky-monhoc/dotDangKyNhomMonHocSaga"
import { dotDangKyMonHocSaga } from "./dangky-monhoc/dotDangKyMonHocSaga"
import { dotDangKyKetQuaSaga } from "./dangky-monhoc/dotDangKyKetQuaSaga"
import { khenThuongKyLuatSaga } from "./khen-thuong-ky-luat/khenThuongKyLuatSaga"
import { chamTietSaga } from "./cham-tiet/chamTietSaga"
import { chamTietLoaiTietSaga } from "./cham-tiet/chamTietLoaiTietSaga"
import { formKhaoSatSaga } from "./form-khaosat/formKhaoSatSaga"
import { formKhaoSatItemSaga } from "./form-khaosat/formKhaoSatItemSaga"
import { formKhaoSatEditorSaga } from "./form-khaosat/formKhaoSatEditorSaga"
import { khaoSatSaga } from "./form-khaosat/khaoSatSaga"
import { appNhomBaiVietSaga } from "./bai-viet/appNhomBaiVietSaga"
import { hocSinhHoTroSaga } from "./hoc-sinh/hocSinhHoTroSaga"
import { mealSaga } from "./mealSaga"
import { diemDanhAnSaga } from "./diem-danh-an/diemDanhAnSaga"
import { lopBoMonTypeSaga } from "./lop-bo-mon/lopBoMonTypeSaga"



export default function* rootSaga() {
    yield all([
        processAccountSaga(),
        accountSaga(),
        authSaga(),
        dictionarySaga(),
        monHocSaga(),
        categorySourceSaga(),
        hocSinhSelectionSaga(),
        soDiemListSaga(),
        soDiemConfigSaga(),
        lopBoMonListSaga(),
        lopBoMonHocSinhSaga(),
        toKhoiSaga(),
        giaoVienSaga(),
        thanhPhanDiemSaga(),
        nhomThanhPhanDiemSaga(),
        giaiDoanSaga(),
        thangDiemSaga(),
        soNhanXetItemSaga(),
        giaoVienNhanXetSaga(),
        loaiPhongSaga(),
        phongHocSaga(),
        thuSaga(),
        tuanSaga(),
        tietSaga(),
        thoiKhoaBieuSaga(),
        thoiKhoaBieuChiTietSaga(),
        plNamHocSaga(),
        hocKySaga(),
        lopSaga(),
        uploadSaga(),
        appMenuSaga(),
        appMenuNhomSaga(),
        appMenuTruongSaga(),
        appAccountSaga(),
        baiVietSaga(),
        baiVietTrangThaiSaga(),
        baiVietViewSaga(),
        baiVietReactSaga(),
        baiVietDetailSaga(),
        appNhomBaiVietSaga(),
        appXinNghiSaga(),
        appYeuCauHoTroSaga(),
        notifySaga(),
        diemDanhSaga(),
        diemDanhStatusSaga(),
        nhanXetHangNgaySaga(),
        localizedResourceSaga(),
        baiTapTypeSaga(),
        baiTapSaga(),
        nhapDiemSaga(),
        monHocSoDiemSaga(),
        baiTapKetQuaSaga(),
        lopChuNhiemSaga(),
        baiTapKetQuaLopSaga(),
        lopBoMonSoDiemSaga(),
        mappingSaga(),
        soDiemMappingSaga(),
        thaiDoHocTapSaga(),
        thaiDoHocTapTieuChiSaga(),
        thongTuSaga(),
        thaiDoHocTapTieuChiSaga(),
        diemVanMinhTieuChiSaga(),
        diemVanMinhKetQuaSaga(),
        soNhanXetSaga(),
        dgdkNlpcSaga(),
        lopHanhChinhSoDiemSaga(),
        dgtxKetQuaSaga(),
        dgtxKetQuaMonHocSaga(),
        dgdkHocTapSaga(),
        baoCaoTienDoSaga(),
        xepHangHocSinhSaga(),
        lopMonHocSaga(),
        tongHopDinhKySaga(),
        baiVietCommentSaga(),
        dotDangKySaga(),
        dotDangKyNhomMonHocSaga(),
        dotDangKyMonHocSaga(),
        dotDangKyKetQuaSaga(),
        khenThuongKyLuatSaga(),
        chamTietSaga(),
        chamTietLoaiTietSaga(),
        formKhaoSatSaga(),
        formKhaoSatItemSaga(),
        formKhaoSatEditorSaga(),
        khaoSatSaga(),
        hocSinhHoTroSaga(),
        mealSaga(),
        diemDanhAnSaga(),
        lopBoMonTypeSaga()
    ])


}
