import axios from 'axios';
import { apiClient } from './apiClient';
import { IHocSinhQuanHeRequest } from '../models/request/hoc-sinh/HocSinhQuanHeRequest';
import { AppAccountProcessUpdateRequest } from '../models/request/app-phuhuynh/AppAccountProcessUpdateRequest';

const processAccountApi = {
    getListSDTPhuhuynh: (keyword: string) => {
        return apiClient.get(`process-account/phu-huynh/danh-sach/${keyword != ''? keyword : 'all'}`)
    },
    getListSDTGiaoVien: (keyword: string) => {
        return apiClient.get(`process-account/giao-vien/danh-sach/${keyword != ''? keyword : 'all'}`)
    },
    getDetailSDTPhuhuynh: (keyword: string) => {
        return apiClient.get(`process-account/phu-huynh/chi-tiet/${keyword != ''? keyword : 'all'}`)
    },
    getDetailSDTGiaoVien: (keyword: string) => {
        return apiClient.get(`process-account/giao-vien/chi-tiet/${keyword != ''? keyword : 'all'}`)
    },
    resetPassAppGiaoVien: (app_id: number) => {
        return apiClient.post(`process-account/giao-vien/set-default-password-app/${app_id}`)
    },    
    deleteAppGiaoVien: (app_id: number) => {
        return apiClient.delete(`process-account/giao-vien/delete-app/${app_id}`)
    },  
    deleteAppPhuHuynh: (app_id: number) => {
        return apiClient.delete(`process-account/phu-huynh/delete-app/${app_id}`)
    },
    deletePhuHuynh: (id: number) => {
        return apiClient.delete(`process-account/phu-huynh/delete/${id}`)
    },
    deleteUser: (user_id: number) => {
        return apiClient.delete(`process-account/user/delete/${user_id}`)
    },
        
    SyncAppGiaoVien: (giaoVienId: number) => {
        return apiClient.post(`process-account/giao-vien/sync-to-app/${giaoVienId}`)
    }, 
    SyncAppPhuHuynh: (phuhuynh_id: number) => {
        return apiClient.post(`process-account/phu-huynh/sync-to-app/${phuhuynh_id}`)
    }, 
    ChangeChildren: (request: IHocSinhQuanHeRequest) => {
        return apiClient.post(`process-account/phu-huynh/change-children`, request)
    },
    ChangeInfoApp: (request: AppAccountProcessUpdateRequest) => {
        return apiClient.post(`process-account/phu-huynh/update-info-app`, request)
    },  

}
export {processAccountApi}