import { INhanXetHangNgayLopSelectRequest } from "../../../models/request/nhan-xet/INhanXetHangNgayLopSelectRequest"
import { INhanXetLopBoMonPublishMarkRequest } from "../../../models/request/nhan-xet/INhanXetLopBoMonPublishMarkRequest"
import { INhanXetLopChuNhiemPublishMarkRequest } from "../../../models/request/nhan-xet/INhanXetLopChuNhiemPublishMarkRequest"
import { INhanXetThaiDoHocTapSelectRequest } from "../../../models/request/nhan-xet/INhanXetThaiDoHocTapSelectRequest"
import { INhanXetLopBoMonRequest, INhanXetLopChuNhiemRequest } from "../../../models/request/nhan-xet/NhanXetChangeDataRequest"
import { INhanXetHangNgayCommentDeleteRequest, INhanXetHangNgayCommentRequest } from "../../../models/request/nhan-xet/NhanXetHangNgayCommentRequest"
import { INhanXetLopBoMonSelectRequest, INhanXetLopChuNhiemSelectRequest } from "../../../models/request/nhan-xet/NhanXetSelectRequest"
import { DanhSachNhanXetTheoLopResponse } from "../../../models/response/nhan-xet/DanhSachNhanXetTheoLopResponse"
import { sis_nhanxet_hangngay_comment } from "../../../models/response/nhan-xet/sis_nhanxet_hangngay_comment"
import { sis_nhanxet_hangngay_lop } from "../../../models/response/nhan-xet/sis_nhanxet_hangngay_lop"
import { sis_thaidohoctap_ketqua } from "../../../models/response/nhan-xet/sis_thaidohoctap_ketqua"
import { ts_phuhuynh } from "../../../models/response/app-phuhuynh/ts_phuhuynh";
import { IActionTypeBase } from "../IActionTypeBase"

export enum eNhanXetHangNgayActionTypeIds {
    SELECT_LOPBOMON_START = "NHANXET_HANGNGAY_SELECT_LOPBOMON_START",
    SELECT_LOPBOMON_SUCCESS = "NHANXET_HANGNGAY_SELECT_LOPBOMON_SUCCESS",
    SELECT_LOPBOMON_ERROR = "NHANXET_HANGNGAY_SELECT_LOPBOMON_ERROR",

    SELECT_LOPCHUNHIEM_START = "NHANXET_HANGNGAY_SELECT_LOPCHUNHIEM_START",
    SELECT_LOPCHUNHIEM_SUCCESS = "NHANXET_HANGNGAY_SELECT_LOPCHUNHIEM_SUCCESS",
    SELECT_LOPCHUNHIEM_ERROR = "NHANXET_HANGNGAY_SELECT_LOPCHUNHIEM_ERROR",

    CHANGE_DANHSACH_NHANXET_HANGNGAY = "CHANGE_DANHSACH_NHANXET_HANGNGAY",
    SHOW_NHANXET_HANGNGAY_MODAL = "SHOW_NHANXET_HANGNGAY_MODAL",

    NHANXET_LOPBOMON_START = "NHANXET_HANGNGAY_NHANXET_LOPBOMON_START",
    NHANXET_LOPBOMON_SUCCESS = "NHANXET_HANGNGAY_NHANXET_LOPBOMON_SUCCESS",
    NHANXET_LOPBOMON_ERROR = "NHANXET_HANGNGAY_NHANXET_LOPBOMON_ERROR",

    NHANXET_LOPCHUNHIEM_START = "NHANXET_HANGNGAY_NHANXET_LOPCHUNHIEM_START",
    NHANXET_LOPCHUNHIEM_SUCCESS = "NHANXET_HANGNGAY_NHANXET_LOPCHUNHIEM_SUCCESS",
    NHANXET_LOPCHUNHIEM_ERROR = "NHANXET_HANGNGAY_NHANXET_LOPCHUNHIEM_ERROR",

    SELECT_DETAIL_START = "NHANXET_HANGNGAY_SELECT_DETAIL_START",
    SELECT_DETAIL_SUCCESS = "NHANXET_HANGNGAY_SELECT_DETAIL_SUCCESS",
    SELECT_DETAIL_ERROR = "NHANXET_HANGNGAY_SELECT_DETAIL_ERROR",

    SELECT_COMMENTS_START = "NHANXET_HANGNGAY_SELECT_COMMENTS_START",
    SELECT_COMMENTS_SUCCESS = "NHANXET_HANGNGAY_SELECT_COMMENTS_SUCCESS",
    SELECT_COMMENTS_ERROR = "NHANXET_HANGNGAY_SELECT_COMMENTS_ERROR",

    SAVE_COMMENTS_START = "NHANXET_HANGNGAY_SAVE_COMMENTS_START",
    SAVE_COMMENTS_SUCCESS = "NHANXET_HANGNGAY_SAVE_COMMENTS_SUCCESS",
    SAVE_COMMENTS_ERROR = "NHANXET_HANGNGAY_SAVE_COMMENTS_ERROR",

    DELETE_COMMENTS_START = "NHANXET_HANGNGAY_DELETE_COMMENTS_START",
    DELETE_COMMENTS_SUCCESS = "NHANXET_HANGNGAY_DELETE_COMMENTS_SUCCESS",
    DELETE_COMMENTS_ERROR = "NHANXET_HANGNGAY_DELETE_COMMENTS_ERROR",

    SELECT_TDHT_LOPBOMON_START = "NHANXET_HANGNGAY_SELECT_TDHT_LOPBOMON_START",
    SELECT_TDHT_LOPBOMON_SUCCESS = "NHANXET_HANGNGAY_SELECT_TDHT_LOPBOMON_SUCCESS",
    SELECT_TDHT_LOPBOMON_ERROR = "NHANXET_HANGNGAY_SELECT_TDHT_LOPBOMON_ERROR",

    NHANXET_TDHT_LOPBOMON_START = "NHANXET_HANGNGAY_NHANXET_TDHT_LOPBOMON_START",
    NHANXET_TDHT_LOPBOMON_SUCCESS = "NHANXET_HANGNGAY_NHANXET_TDHT_LOPBOMON_SUCCESS",
    NHANXET_TDHT_LOPBOMON_ERROR = "NHANXET_HANGNGAY_NHANXET_TDHT_LOPBOMON_ERROR",

    SHOW_HOCTAP_HANGNGAY_MODAL = "SHOW_HOCTAP_HANGNGAY_MODAL",

    SELECT_HOCTAP_START = "NHANXET_HANGNGAY_SELECT_HOCTAP_START",
    SELECT_HOCTAP_SUCCESS = "NHANXET_HANGNGAY_SELECT_HOCTAP_SUCCESS",
    SELECT_HOCTAP_ERROR = "NHANXET_HANGNGAY_SELECT_HOCTAP_ERROR",

    SAVE_HOCTAP_START = "NHANXET_HANGNGAY_SAVE_HOCTAP_START",
    SAVE_HOCTAP_SUCCESS = "NHANXET_HANGNGAY_SAVE_HOCTAP_SUCCESS",
    SAVE_HOCTAP_ERROR = "NHANXET_HANGNGAY_SAVE_HOCTAP_ERROR",

    PUBLISHMARK_LOPBOMON_START = "NHANXET_HANGNGAY_PUBLISHMARK_LOPBOMON_START",
    PUBLISHMARK_LOPBOMON_SUCCESS = "NHANXET_HANGNGAY_PUBLISHMARK_LOPBOMON_SUCCESS",
    PUBLISHMARK_LOPBOMON_ERROR = "NHANXET_HANGNGAY_PUBLISHMARK_LOPBOMON_ERROR",

    PUBLISHMARK_LOPCHUNHIEM_START = "NHANXET_HANGNGAY_PUBLISHMARK_LOPCHUNHIEM_START",
    PUBLISHMARK_LOPCHUNHIEM_SUCCESS = "NHANXET_HANGNGAY_PUBLISHMARK_LOPCHUNHIEM_SUCCESS",
    PUBLISHMARK_LOPCHUNHIEM_ERROR = "NHANXET_HANGNGAY_PUBLISHMARK_LOPCHUNHIEM_ERROR",

    LOAD_PHUHUYNH_VIEW_START = "NHANXET_HANGNGAY_LOAD_PHUHUYNH_VIEW_START",
    LOAD_PHUHUYNH_VIEW_SUCCESS = "NHANXET_HANGNGAY_LOAD_PHUHUYNH_VIEW_SUCCESS",
    LOAD_PHUHUYNH_VIEW_ERROR = "NHANXET_HANGNGAY_LOAD_PHUHUYNH_VIEW_ERROR",
}

export interface INhanXetHangNgaySelectLopBoMonStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_START, INhanXetLopBoMonSelectRequest>{}
export interface INhanXetHangNgaySelectLopBoMonSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_SUCCESS, DanhSachNhanXetTheoLopResponse[]>{}
export interface INhanXetHangNgaySelectLopBoMonErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_ERROR, string>{}
export interface INhanXetHangNgaySelectLopChuNhiemStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_START, INhanXetLopChuNhiemSelectRequest>{}
export interface INhanXetHangNgaySelectLopChuNhiemSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS, DanhSachNhanXetTheoLopResponse[]>{}
export interface INhanXetHangNgaySelectLopChuNhiemErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_ERROR, string>{}

export interface INhanXetHangNgayShowModal  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SHOW_NHANXET_HANGNGAY_MODAL, boolean>{}
export interface INhanXetHangNgayChangeDanhSachNhanXetHangNgay extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.CHANGE_DANHSACH_NHANXET_HANGNGAY, DanhSachNhanXetTheoLopResponse[]>{}

export interface INhanXetHangNgayLopBoMonStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_START, INhanXetLopBoMonRequest>{}
export interface INhanXetHangNgayLopBoMonSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_SUCCESS, undefined>{}
export interface INhanXetHangNgayLopBoMonErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_ERROR, string>{}

export interface INhanXetHangNgayLopChuNhiemStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_START, INhanXetLopChuNhiemRequest>{}
export interface INhanXetHangNgayLopChuNhiemSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_SUCCESS,undefined>{}
export interface INhanXetHangNgayLopChuNhiemErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_ERROR, string>{}

export interface INhanXetHangNgaySelectDetailStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_START, number>{}
export interface INhanXetHangNgaySelectDetailSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_SUCCESS, DanhSachNhanXetTheoLopResponse>{}
export interface INhanXetHangNgaySelectDetailErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_ERROR, string>{}

export interface INhanXetHangNgaySelectCommentsStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_START, number>{}
export interface INhanXetHangNgaySelectCommentsSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_SUCCESS, sis_nhanxet_hangngay_comment[]>{}
export interface INhanXetHangNgaySelectCommentsErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_ERROR, string>{}

export interface INhanXetHangNgaySaveCommentsStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_START, INhanXetHangNgayCommentRequest>{}
export interface INhanXetHangNgaySaveCommentsSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_SUCCESS,undefined>{}
export interface INhanXetHangNgaySaveCommentsErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_ERROR, string>{}

export interface INhanXetHangNgayDeleteCommentsStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_START, INhanXetHangNgayCommentDeleteRequest>{}
export interface INhanXetHangNgayDeleteCommentsSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_SUCCESS,undefined>{}
export interface INhanXetHangNgayDeleteCommentsErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_ERROR, string>{}

export interface INhanXetHangNgaySelectTDHTLopBoMonStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_START, INhanXetThaiDoHocTapSelectRequest>{}
export interface INhanXetHangNgaySelectTDHTLopBoMonSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_SUCCESS, sis_thaidohoctap_ketqua[]>{}
export interface INhanXetHangNgaySelectTDHTLopBoMonErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_ERROR, string>{}

export interface INhanXetHangNgayTDHTLopBoMonStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_START, sis_thaidohoctap_ketqua>{}
export interface INhanXetHangNgayTDHTLopBoMonSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_SUCCESS, undefined>{}
export interface INhanXetHangNgayTDHTLopBoMonErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_ERROR, string>{}

export interface IHocTapHangNgayShowModal  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SHOW_HOCTAP_HANGNGAY_MODAL, boolean>{}
export interface INhanXetHangNgaySelectHoctapStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_START, INhanXetHangNgayLopSelectRequest>{}
export interface INhanXetHangNgaySelectHoctapSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_SUCCESS, sis_nhanxet_hangngay_lop>{}
export interface INhanXetHangNgaySelectHoctapErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_ERROR, string>{}

export interface INhanXetHangNgaySaveHoctapStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_START, sis_nhanxet_hangngay_lop>{}
export interface INhanXetHangNgaySaveHoctapSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_SUCCESS,undefined>{}
export interface INhanXetHangNgaySaveHoctapErr  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_ERROR, string>{}

export interface INhanXetHangNgayPublishMarkLopBoMonStart extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_START, INhanXetLopBoMonPublishMarkRequest> { }
export interface INhanXetHangNgayPublishMarkLopBoMonSuccess extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_SUCCESS, undefined> { }
export interface INhanXetHangNgayPublishMarkLopBoMonErr extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_ERROR, string> { }

export interface INhanXetHangNgayPublishMarkLopChuNhiemStart extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START, INhanXetLopChuNhiemPublishMarkRequest> { }
export interface INhanXetHangNgayPublishMarkLopChuNhiemSuccess extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_SUCCESS, undefined> { }
export interface INhanXetHangNgayPublishMarkLopChuNhiemErr extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_ERROR, string> { }

export interface IPhuHuynhViewLoadStart  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.LOAD_PHUHUYNH_VIEW_START, number>{}
export interface IPhuHuynhViewLoadSuccess  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.LOAD_PHUHUYNH_VIEW_SUCCESS,ts_phuhuynh[]>{}
export interface IPhuHuynhViewLoadError  extends IActionTypeBase<eNhanXetHangNgayActionTypeIds.LOAD_PHUHUYNH_VIEW_ERROR, string>{}

export type INhanXetHangNgayActionTypes = INhanXetHangNgaySelectLopBoMonStart | INhanXetHangNgaySelectLopBoMonSuccess | INhanXetHangNgaySelectLopBoMonErr |
INhanXetHangNgaySelectLopChuNhiemStart | INhanXetHangNgaySelectLopChuNhiemSuccess | INhanXetHangNgaySelectLopChuNhiemErr |
INhanXetHangNgayShowModal | INhanXetHangNgayChangeDanhSachNhanXetHangNgay |
INhanXetHangNgayLopBoMonStart | INhanXetHangNgayLopBoMonSuccess | INhanXetHangNgayLopBoMonErr |
INhanXetHangNgayLopChuNhiemStart | INhanXetHangNgayLopChuNhiemSuccess | INhanXetHangNgayLopChuNhiemErr |
INhanXetHangNgaySelectDetailStart | INhanXetHangNgaySelectDetailSuccess | INhanXetHangNgaySelectDetailErr |
INhanXetHangNgaySelectCommentsStart | INhanXetHangNgaySelectCommentsSuccess | INhanXetHangNgaySelectCommentsErr |
INhanXetHangNgaySaveCommentsStart | INhanXetHangNgaySaveCommentsSuccess | INhanXetHangNgaySaveCommentsErr |
INhanXetHangNgayDeleteCommentsStart | INhanXetHangNgayDeleteCommentsSuccess | INhanXetHangNgayDeleteCommentsErr |
INhanXetHangNgaySelectTDHTLopBoMonStart | INhanXetHangNgaySelectTDHTLopBoMonSuccess | INhanXetHangNgaySelectTDHTLopBoMonErr |
INhanXetHangNgayTDHTLopBoMonStart | INhanXetHangNgayTDHTLopBoMonSuccess | INhanXetHangNgayTDHTLopBoMonErr |
IHocTapHangNgayShowModal | INhanXetHangNgaySelectHoctapStart | INhanXetHangNgaySelectHoctapSuccess | INhanXetHangNgaySelectHoctapErr |
INhanXetHangNgaySaveHoctapStart | INhanXetHangNgaySaveHoctapSuccess | INhanXetHangNgaySaveHoctapErr |
INhanXetHangNgayPublishMarkLopBoMonStart | INhanXetHangNgayPublishMarkLopBoMonSuccess | INhanXetHangNgayPublishMarkLopBoMonErr |
INhanXetHangNgayPublishMarkLopChuNhiemStart | INhanXetHangNgayPublishMarkLopChuNhiemSuccess | INhanXetHangNgayPublishMarkLopChuNhiemErr |
IPhuHuynhViewLoadStart | IPhuHuynhViewLoadSuccess | IPhuHuynhViewLoadError