import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eNhanXetHangNgayActionTypeIds, INhanXetHangNgayActionTypes } from "../../action-types/nhan-xet/INhanXetHangNgayActionTypes";
import { INhanXetHangNgayPageState } from "../../page-state-model/nhan-xet/INhanXetHangNgayPageState";
import { ts_phuhuynh } from "../../../models/response/app-phuhuynh/ts_phuhuynh";


const initialState: INhanXetHangNgayPageState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_nhanxet_hocsinhs: [],
    is_show_nhanxet_modal: false,
    sis_nhanxet_hocsinhs_modal: [],
    sis_nhanxet_hocsinh: undefined,
    sis_nhanxet_hangngay_comments: [],
    sis_thaidohoctap_ketquas: [],
    nhanxet_thaido_status: ePageBaseStatus.is_not_initialization,
    is_show_hoctap_modal: false,
    sis_nhanxet_hangngay_lop: undefined,
    ts_phuhuynhs: []
}

export const nhanXetHangNgayReducer = (state: INhanXetHangNgayPageState = initialState, action: INhanXetHangNgayActionTypes): INhanXetHangNgayPageState => {
    switch (action.type) {
        case eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinhs: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinhs: []
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinhs: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinhs: []
            }
        case eNhanXetHangNgayActionTypeIds.SHOW_NHANXET_HANGNGAY_MODAL:
            return {
                ...state,
                is_show_nhanxet_modal: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SHOW_HOCTAP_HANGNGAY_MODAL:
            return {
                ...state,
                is_show_hoctap_modal: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.CHANGE_DANHSACH_NHANXET_HANGNGAY:
            return {
                ...state,
                sis_nhanxet_hocsinhs_modal: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_SUCCESS:
            NotifyHelper.Success("Nhận xét lớp bộ môn thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_nhanxet_modal: false
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_nhanxet_modal: false
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_SUCCESS:
            NotifyHelper.Success("Nhận xét lớp chủ nhiệm thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_nhanxet_modal: false
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_nhanxet_modal: false
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinh: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinh: undefined
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hangngay_comments: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hangngay_comments: []
            }
        case eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_SUCCESS:
            NotifyHelper.Success("Lưu bình luận thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        case eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_SUCCESS:
            NotifyHelper.Success("Xóa bình luận thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_START:
            return {
                ...state,
                nhanxet_thaido_status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_SUCCESS:
            return {
                ...state,
                nhanxet_thaido_status: ePageBaseStatus.is_completed,
                sis_thaidohoctap_ketquas: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                nhanxet_thaido_status: ePageBaseStatus.is_completed,
                sis_nhanxet_hocsinhs: []
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_START:
            return {
                ...state,
                nhanxet_thaido_status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_SUCCESS:
            return {
                ...state,
                nhanxet_thaido_status: ePageBaseStatus.is_need_reload,
            }
        case eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                nhanxet_thaido_status: ePageBaseStatus.is_completed,
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hangngay_lop: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_nhanxet_hangngay_lop: undefined
            }
        case eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_SUCCESS:
            NotifyHelper.Success("Lưu nội dung học tập thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eNhanXetHangNgayActionTypeIds.LOAD_PHUHUYNH_VIEW_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhanXetHangNgayActionTypeIds.LOAD_PHUHUYNH_VIEW_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ts_phuhuynhs: action.payload
            }
        case eNhanXetHangNgayActionTypeIds.LOAD_PHUHUYNH_VIEW_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ts_phuhuynhs: []
            }
        default:
            return state;
    }

}