import { NotifyHelper } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eBaiVietReactActionTypeIds, IBaiVietReactActionTypes } from './../../action-types/bai-viet/IBaiVietReactActionTypes';
import { IBaiVietReactPageState } from './../../page-state-model/bai-viet/IBaiVietReactPageState';

const initialState: IBaiVietReactPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_baiviet_reacts: []
}

export const baiVietReactReducer = (state: IBaiVietReactPageState = initialState, action: IBaiVietReactActionTypes): IBaiVietReactPageState => {
    switch (action.type) {
        case eBaiVietReactActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
                app_baiviet_reacts: []
            }
        case eBaiVietReactActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet_reacts: action.payload
            }
        case eBaiVietReactActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet_reacts: []
            }
        default:
            return state;
    }

}