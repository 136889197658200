import axios from 'axios';
import { appInfo } from '../AppInfo';
import { ILoginSSORequestModel } from '../models/request/account';
import { apiClient } from './apiClient';
import { ICauHinh } from '../models/response/account/cau-hinh';

const accountApi = {
    getUser: () => {
        return apiClient.post(`account/detail`)
    },
    logInSSO: (data: ILoginSSORequestModel) => {
        return axios.post(`${appInfo.baseApiURL}/account/login`, data)
    },
    logOut() {
        return apiClient.post(`api/logout`, undefined,appInfo.homeURL)
    },
    menuCountData: (dm_truong_id: number) => {
        return apiClient.get(`menu/truong/${dm_truong_id}/count-by-type`)
    },
    getConfig: (dm_truong_id: number) => {
        return apiClient.get(`truong/${dm_truong_id}/cau-hinh`)
    },
    updateConfig: (data: ICauHinh) => {
        return apiClient.put(`truong/cau-hinh`, data)
    },

}
export {accountApi}